export const BASE_URL = 'https://crm.belfordwoodsschool.com/api';
export const AUDIO_URL = 'https://crm.belfordwoodsschool.com/audio_file/';
export const PAYMENT = {
    'PAYMENT_URL': 'https://test.api.dibspayment.eu/v1/payments',
    'CHECKOUT_URL': 'https://test.checkout.dibspayment.eu/v1/checkout.js?v=1',
    'SECRET_KEY' : 'test-secret-key-92c78feb604a47cba538951c579859cc',
    'CHECKOUT_KEY' : 'test-checkout-key-aaea7ae277f84c2da14da95c0c28d31b',
}

export const APIENDPOINTS = {
    'STUDENT_REGISTER':'/student-register',
    'COURSES':'/courses',
    'LOGIN':'/login',
    'FORGOT_PWD':'/forget-password?email=',
    'LEVELS':'/levels',
    'CONTACT_US':'/contact-us',
    'CALENDER':'/calendar',
    'BOOK_APPOINTMENT':'/book-appointment',
    'USER':'/user',
    'UNITS':'/units',
    'GENERAL_TEST':'/general-test',
    'TEST':'/test',
    'GUEST_USER':'/guest-user',
    'TEST_SUBMIT':'/test-submit',
    'RESULT_TEST':'/test-result',
    'PAYMENT_HISTORY':'/payment-history',
    'UNIT_TEST_STORE':'/unit-test-store',
    'GENERAL_TEST_STORE':'general-test-store',
    'STUDENT_PROGRESS':'student/progress/tracking',
    'STUDENT_PROGRESS_STORE':'student/progress',
    'STUDENT_FINAL_FEES':'student/final/test/fees',
    'GEOCODE':'https://api.bigdatacloud.net/data/reverse-geocode-client',
    'UNIT_EXERCISE_RESULT':'unit-exercise-result',
    'CANCEL_SUBSCRIPTION':'cancel-subscription',
    'UNIT_EXERCISE_STORE':'unit-exercise-store',
    'UPDATE_PASSWORD':'update-password',
    'APPOINTMENT_DELETE':'/book-appointment/delete',
    'CANCEL_TRIAL':'/cancel-trial',
    'UNIT_EXERCISE_PAGE':'/unit-exercise-page',
}