import React, { useEffect, useState } from 'react'
import Body from './common/Body'
import PrivateMenu from './common/PrivateMenu';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API } from '../helper/api';
import { getCurrentUser, getLoggendUser } from "../helper/Authorization";
import { Hourglass } from 'react-loader-spinner';

const api = new API();

const Units = () => {
    const [units, setUnits] = useState([]);
    const [finalExamPaid, setFinalExamPaid] = useState(0);
    const [completedUnit, setCompletedUnit] = useState(0);
    const [isCompleted, setIsCompleted] = useState(1);
    const [currentUser, setCurrentUser] = useState([]);
    const [isPayment, setPayment] = useState(false);
    const [hasMiddleTest, setHasMiddleTest] = useState(false);
    const [loader, setLoader] = useState(false);
    const [getResultDetails, setResultDetails] = useState(0);

    useEffect(() => {
        getUnits();
        const user = getCurrentUser();
        const currentLoignDetails = getLoggendUser();
        if (user.student) {
            setCurrentUser(user.student);
            checkPaymentStatus(user.created_at);
            setFinalExamPaid(currentLoignDetails?.student?.is_final_exam_fees_paid);
            getResultTest();
        }
    }, [])

    const checkPaymentStatus = async(date) => {
        const givenDate = new Date(date);
        const sevenDaysLater = new Date(givenDate);
        sevenDaysLater.setHours(0, 0, 0, 0);
        sevenDaysLater.setDate(givenDate.getDate() + 7);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        setPayment(sevenDaysLater.getTime() <= currentDate.getTime());
    }

    const getUnits = async () => {
        try {
            setLoader(true);
            const response = await api.get("UNITS");
            if (response.status === true) {
                const isCompletedCount = response.data.filter(unit => unit.is_completed === 1).length;
                console.log({isCompletedCount});
                setIsCompleted(isCompletedCount === 0 ? 1 : isCompletedCount+1);
                setHasMiddleTest(isCompletedCount >= 10 ? true : false);
                setUnits(response.data.sort((a, b) => a.id - b.id))
                console.log(response.data.length);
                console.log(isCompletedCount);
                if(response.data.length === isCompletedCount){
                    setCompletedUnit(1);
                }
                setLoader(false);
            }
        } catch (error) {
            console.error("Error while get Units", error);
            toast.error(error.data.message);
            setLoader(false);
        }
    }

    const bookFinalExam = async () => {
        try {
            const response = await api.get("STUDENT_FINAL_FEES");
            if (response.status === true) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            console.error("Error while get Units", error);
            toast.error(error.data.message);
        }
    }

    const payNow = async () => {
        try {
            const response = await api.get("CANCEL_TRIAL");
            if (response.status === true) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            console.error("Error while get Units", error);
            toast.error(error.data.message);
        }
    }

    const getResultTest = async () => {
        try {
          const response = await api.post("RESULT_TEST",{
            "is_middle_test":1
          });
          if (response.status === "success") {
            const result = response?.data;
            // console.log(result);
            // console.log(result[result.length - 1].correct_answer_count);
            console.log('correct_answer_count',result[result.length - 1]?.correct_answer_count);
            setResultDetails(result.length > 0 ? result[result.length - 1]?.correct_answer_count : 0);
          }
        } catch (error) {
          console.error("Error while get General Test", error);
          toast.error(error.data?.message);
        }
    };

    return (
        <Body>
            { loader &&
                <div className="bws-loader">
                    <Hourglass
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#FFC356', '#FFC356']}
                    />
                </div>
            }
            <section className="belford-units-hero-section" id="belford-units-hero-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-9">
                            <div className="belford-units-hero-content text-center">
                                <PrivateMenu />
                                <h1><span className="belford-title-color">Units</span></h1>
                                {
                                    setPayment &&
                                    <Link to="#" className="belford-btn" onClick={() => payNow() }>Pay now</Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-units-about-section" id="belford-units-about-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-7">
                            <div className="belford-units-about-content">
                                <ul>
                                    { units.map((unit,key) => {
                                        let shouldDisable = '';
                                        // console.log({isPayment});
                                        if(isPayment || key === 0 || key === 1) {
                                            shouldDisable = key >= isCompleted ? true : false;
                                        }
                                        // else if(key === 0 ){
                                        //     shouldDisable = key >= isCompleted ? true : false;
                                        // }
                                        //  else if(key === 1){
                                        //     shouldDisable = key >= isCompleted? true : false;
                                        // }
                                        else {
                                            shouldDisable = true;
                                        }
                                        // console.log(getResultDetails);
                                        console.log(key + 1, '--------', getResultDetails < 11 && currentUser.length !== 0 && currentUser.level.id !== 1 && hasMiddleTest && key>10)
                                        if (getResultDetails < 11 && currentUser.length !== 0 && currentUser.level.id !== 1 && hasMiddleTest && key>9){
                                            console.log();
                                            shouldDisable = true;
                                        }
                                        console.log(shouldDisable);
                                        return <li key={key} className={(shouldDisable ? 'unit-disable' : '')}>
                                            <Link to={`/material/${unit.id}`} >
                                                <h5>{unit.name}</h5>
                                                <p>{unit.title}</p>
                                            </Link>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-7">
                            <div className="belford-journey-btn">
                                {/* {console.log('-----------------------')}
                                {console.log(currentUser.length !== 0 && currentUser.level.id !== 1 && hasMiddleTest)}
                                {console.log(getResultDetails < 11 && currentUser.length !== 0 && currentUser.level.id !== 1 && hasMiddleTest)} */}
                                {getResultDetails < 10 && currentUser.length !== 0 && currentUser.level.id !== 1 && hasMiddleTest && <Link to="/middle-test" className="belford-btn belford-btn-primary">Take The Mid-Level Test</Link>}
                                {finalExamPaid === 0  && completedUnit === 1 && <Link to="#" className="belford-btn" onClick={() => bookFinalExam() }>Book Your Final Exam</Link>}
                                {/* <Link to="/middle-test" className="belford-btn belford-btn-primary">Take The Mid-Level Test</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Units