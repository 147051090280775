import React, { useEffect, useState } from "react";
import Body from "./common/Body";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { API } from "../helper/api";
import { getCurrentUser } from "../helper/Authorization";
import { AUDIO_URL } from "../helper/url_api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  arr,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Hourglass } from 'react-loader-spinner';
import parse from 'html-react-parser';

const api = new API();

function Material() {
  const { id } = useParams();
  const [materialQuestion, setMaterialQuestion] = useState([]);
  const [page, setPage] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [testAnswer, setTestAnswer] = useState([]);
  const [isCorrect, setIsCorrect] = useState(null);
  // const [currentMaterialId, setCurrentMaterial] = useState(null);

  useEffect(() => {
    setCurrentUser(getCurrentUser());
    getMaterial();

    const currentPageData = sessionStorage.getItem("currentPage");
    if (currentPageData) {
      const parsedData = JSON.parse(currentPageData);
      const currentUnit = parsedData.currentUnit;
      if(currentUnit === id){
        setPage(parsedData.currentMaterial);
      }
    }
  }, []);

  const getMaterial = async () => {
    const token = sessionStorage.getItem("auth_token");
    try {
      setLoader(true);
      const response = await axios.get(
        `https://crm.belfordwoodsschool.com/api/unit/question/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === true) {
        setMaterialQuestion(
          response.data.sort((a, b) => a.position - b.position)
        );
        setLoader(false);
      }
    } catch (error) {
      console.error("Error while get MATERIAL", error);
      toast.error(error.data.message);
      setLoader(false);
    }
  };

  const onFinish = async () => {
    saveAnswers();
    try {
      const response = await api.post("STUDENT_PROGRESS_STORE", {
        unit_id: id,
        level_id: currentUser.student.level_id,
        course_id: currentUser.student.course_id,
        is_completed: 1,
      });
      if (response.status === true) {
        toast.success("submit successfully.");
      }
      setTimeout(() => {
        navigate("/units");
      }, 3000);
    } catch (error) {
      console.error("Error while General test submit", error);
    }
  };

  const selectAnswer = (questionId, optionId, answer, option) => {

      setSelectedAnswers((prev) => ({
        ...prev,
        [questionId]: option,
      }));
      setIsCorrect(option.is_correct);

    // Check if there's already an answer for this question
    const existingAnswerIndex = testAnswer.findIndex(
      (answer) => answer.question_id === questionId
    );

    // If there's an existing answer, update it
    if (existingAnswerIndex !== -1) {
      const updatedTestAnswer = [...testAnswer];
      updatedTestAnswer[existingAnswerIndex] = {
        ...updatedTestAnswer[existingAnswerIndex],
        option_id: optionId,
        answer: answer
      };
      setTestAnswer(updatedTestAnswer);
    } else {
      // If there's no existing answer, add a new one
      setTestAnswer([
        ...testAnswer,
        {
          question_id: questionId,
          option_id: optionId,
          answer: answer
        },
      ]);
    }
  };

  const saveAnswers = async (currentPage) => {
    if(currentPage) {
      console.log(currentPage?.['questions']?.[0]?.['type']);
      if(currentPage?.['questions']?.[0]?.['type'] === "textarea") {
        const request = {
            user_id: currentUser.id,
            unit_id: Number(id),
            material_id: currentPage.id,
            page_number: currentPage.position,
            is_final_page : 0,
            questions: [{
              answer: description,
              option_id:currentPage?.['questions']?.[0]?.['id'],
              question_id:currentPage?.['questions']?.[0]?.['id']
          }]
        };
        await api.post("UNIT_EXERCISE_STORE", request);
      } else if(Object.keys(testAnswer).length > 0 && currentPage) {
        console.log(currentPage);
        const request = {
            user_id: currentUser.id,
            unit_id: Number(id),
            material_id: currentPage.id,
            page_number: currentPage.position,
            is_final_page : 0,
            questions: testAnswer
        };
        await api.post("UNIT_EXERCISE_STORE", request);
        setTestAnswer([]);
      }
    }
  };

  const getExerciseAnswer = async (currentPage) => {
    if(currentPage) {
        const request = {
            page_number: currentPage,
            unit_id: Number(id),
        };
        const result = await api.post("UNIT_EXERCISE_PAGE", request);
        if(result.status === 'success'){
          setTestAnswer(result.data.questionAns);
        }
      }
  };

  const modifyHtml = (html) => {
    return parse(html, {
      replace: domNode => {
        if (domNode.name === 'audio') {
          const playIcon = <button onClick={() => domNode.play()}>▶️</button>;
          return (
            <div>
              {playIcon}
              {domNode}
            </div>
          );
        }
      }
    });
  };

  return (
    <Body>
      {loader && (
        <div className="bws-loader">
          <Hourglass
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#FFC356", "#FFC356"]}
          />
        </div>
      )}
      <section
        className="belford-calendar-hero-section"
        id="belford-calendar-hero-section"
      >
        <div className="container">
          <div className="row gy-4 justify-content-center align-items-center">
            <div className="col">
              <div className="belford-register-box text-center">
                <h1>
                  <span style={{ color: "#fff" }}>
                    {materialQuestion.length !== 0 &&
                      materialQuestion[page].unit.name +
                        " - " +
                        materialQuestion[page].unit.title}
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="belford-test-hero-section material-section"
        id="belford-test-hero-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="belford-test-box  material-section-innner">
                {materialQuestion.length !== 0 && (
                  <div className="belford-test-box-info">
                    <div className="belford-test-box-title">
                      <h5>{page + 1}</h5>
                      <h4>{materialQuestion[page].name}</h4>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: materialQuestion[page].description.replaceAll(
                          "../../audio_file/",
                          AUDIO_URL
                        ),
                      }}
                      className="p-3"
                    ></div>
                    <div className="p-3">
                      {materialQuestion[page].questions.length > 0 &&
                        materialQuestion[page].questions.map((question, i) => (
                          <div className="belford-test-box-info" key={i}>
                            <div className="belford-test-box-title">
                              <h5>{i + 1}</h5>
                              <h4>{question.question}</h4>
                            </div>
                            <ul>
                            {question.options.map((option) => {
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  key={option.id}
                                  onClick={() => {
                                    selectAnswer(question.id, option.id, option.option, option);
                                  }}

                                  className={`option ${
                                    selectedAnswers[question.id]
                                      ? selectedAnswers[question.id].is_correct
                                        ? selectedAnswers[question.id].id ===
                                          option.id
                                          ? "correct-answer"
                                          : "wrong-selector"
                                        : selectedAnswers[question.id].id ===
                                          option.id
                                        ? "wrong-answer"
                                        : "wrong-selector"
                                      : ""
                                  }`}
                                >
                                  {option.option}
                                </li>
                              );
                            })}
                          </ul>
                            {question.type === "textarea" && (
                              <textarea
                                className="w-100 "
                                style={{ fontSize: "22px" }}
                                rows={6}
                                value={description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                  selectAnswer(question.id, {
                                    answer: e.target.value,
                                  });
                                }}
                              ></textarea>
                            )}
                          </div>
                        ))}
                    </div>
                    <div className="py-3 d-flex justify-content-between">
                      {page !== 0 && (
                        <button
                          className="belford-btn"
                          onClick={() => {
                            window.scrollTo(0, 0);
                            setPage(page - 1);
                          }}
                          disabled={page === 0}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} /> Prev
                        </button>
                      )}
                      {page === materialQuestion.length - 1 ? (
                        <button className="belford-btn" onClick={() => {
                          saveAnswers(materialQuestion[page]);
                          onFinish();
                          }}>
                          Finish
                        </button>
                      ) : (
                        <button
                          className="belford-btn ms-auto"
                          onClick={() => {
                            saveAnswers(materialQuestion[page]);
                            window.scrollTo(0, 0);
                            setPage(page + 1);
                            getExerciseAnswer(page + 1);
                            sessionStorage.setItem("currentPage", JSON.stringify({'currentUnit': id, 'currentMaterial': page + 1}));
                          }}
                          disabled={page === materialQuestion.length - 1}
                        >
                          Next <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Body>
  );
}

export default Material;
