/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Body from "./common/Body";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateMenu from "./common/PrivateMenu";
import { getCurrentUser } from "./../helper/Authorization";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import { API } from "../helper/api";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const api = new API();

const Profile = () => {
  const [key, setKey] = useState("Profile");
  const { t } = useTranslation()
  const { handleSubmit, register, formState: { errors }, getValues } = useForm();
  const [userDetails, setUserDetails] = useState(null)
  const [result, setResult] = useState([])
  const [getResultDetails, setResultDetails] = useState([]);
  const [getPaymentHistroy, setPaymentHistroy] = useState([]);
  const [classShow, setClassShow] = useState(false);

  useEffect(() => {
    getResult()
    const currentUser = getCurrentUser();
    if (currentUser.student) {
      setUserDetails(currentUser);
      getResultTest();
      getPaymentHistory();
    }
  }, []);

  const getResult = async () => {
    try {
      const response = await api.get('UNIT_EXERCISE_RESULT');
      console.log(response, "response")
      if (response.status === "success") {
        setResult(response.data)
      }
    } catch (error) {
      console.error('Error get Result', error);
      toast.error(error.data.message);
    }
  }

  const getResultTest = async () => {
    try {
      const response = await api.post("RESULT_TEST", {
        "is_middle_test": 1
      });
      if (response.status === "success") {
        setResultDetails(response.data);
      }
    } catch (error) {
      console.error("Error while get General Test", error);
      toast.error(error.data?.message);
    }
  };

  const getPaymentHistory = async () => {
    try {
      const response = await api.get("PAYMENT_HISTORY");
      if (response.status === true) {
        setPaymentHistroy(response.data);
        console.log(setPaymentHistroy);
      }
    } catch (error) {
      console.error("Error while get Payment History", error);
      toast.error(error.data?.message);
    }
  };

  const handleClassClose = () => setClassShow(false);

  const getCancelSubscribtionModel = async () => {
    console.log('modal closed !!!');
    setClassShow(true);
  };

  const getCancelSubscribtion = async () => {
    try {
      const response = await api.get("CANCEL_SUBSCRIPTION");
      if (response.status === "success") {
        handleClassClose();
        toast.success("Your subscription has been canceled.");
      }
    } catch (error) {
      handleClassClose();
      console.error("Error while get Payment History", error);
      toast.error(error.data?.message);
    }
  };

  const onSubmit = async (values) => {
    try {
      const response = await api.post("UPDATE_PASSWORD", {
        "email" : userDetails.email,
        "new_password" : values.password
      });
      if (response.status) {
        toast.success(response?.message);
      }
    } catch (error) {
      console.error("Error while get General Test", error);
      toast.error(error.data?.message);
    }
  }

  return (
    <Body>
      <ToastContainer closeButton={false} limit={1} />
      <section
        className="belford-withdraw-hero-section belford-withdraw-golden"
        id="belford-withdraw-hero-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="belford-withdraw-hero-content text-center">
                <PrivateMenu />
                <h1>
                  <span className="belford-title-yellow-color">
                    Welcome to The Platform
                  </span>
                </h1>
                <p>
                  Your Level <span>{userDetails !== null && userDetails.student.level.name}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="belford-translation-about-section"
        id="belford-translation-about-section"
      ></section>
      <section
        className="belford-form-section belford-register-form-section"
        id="belford-register-section"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="belford-form-box profile-box">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                >
                  <Tab
                    eventKey="Profile"
                    title="Profile"
                    className="border-end border-bottom border-start p-5 rounded-bottom"
                  >
                    <div className="row g-4">
                      <div className="col-12 col-md-6 p-2">
                        <div className="fw-light">First name</div>
                        <div className="border-bottom p-2 fw-medium">
                          {userDetails !== null && userDetails.firstname}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 p-2">
                        <div className="fw-light">Last name</div>
                        <div className="border-bottom p-2 fw-medium">
                          {userDetails !== null && userDetails.lastname}
                        </div>
                      </div>
                    </div>
                    <div className="row g-4">
                      <div className="col-12 col-md-6 p-2">
                        <div className="fw-light">Email</div>
                        <div className="border-bottom p-2 fw-medium">
                          {userDetails !== null && userDetails.email}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 p-2">
                        <div className="fw-light">Country</div>
                        <div className="border-bottom p-2 fw-medium">
                          {userDetails !== null && userDetails.student.country}
                        </div>
                      </div>
                    </div>
                    <div className="row g-4">
                      <div className="col-12 col-md-6 p-2">
                        <div className="fw-light">Phone</div>
                        <div className="border-bottom p-2 fw-medium">
                          {userDetails !== null && userDetails.phone}
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    title="Course"
                    eventKey="course"
                    className="border-end border-bottom border-start p-5 rounded-bottom"
                  >
                    <div className="row g-4">
                      <div className="col-12 col-md-6 p-2">
                        <div className="fw-light">Package Ribbon</div>
                        <div className="border-bottom p-2 fw-medium">
                          {userDetails !== null && userDetails.student.course.name}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 p-2">
                        <div className="fw-light">Level</div>
                        <div className="border-bottom p-2 fw-medium">
                          {userDetails !== null && userDetails.student.level.name}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row g-4">
                      <div className="col-12 col-md-6 p-2">
                        <div className="fw-light">Progress</div>
                        <div className="border-bottom p-2 fw-medium">
                          Unit 4
                        </div>
                      </div>
                    </div> */}
                  </Tab>
                  <Tab
                    title="Payment"
                    eventKey="payment"
                    className="border-end border-bottom border-start p-5 rounded-bottom"
                  >
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Amount</th>
                          <th scope="col">status</th>
                          <th scope="col">Method</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getPaymentHistroy.length > 0 && getPaymentHistroy.map((rowData) => {
                          return <tr key={rowData.created_at}>
                            <td>{rowData.amount}</td>
                            <td>{rowData.payment_status}</td>
                            <td>{rowData.payment_method}</td>
                            <td>{moment(rowData.created_at).format("DD-MM-YYYY")}</td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </Tab>
                  <Tab
                    title="Result"
                    eventKey="Result"
                    className="border-end border-bottom border-start p-5 rounded-bottom"
                  >
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Exam</th>
                          <th scope="col">Page</th>
                          <th scope="col">Title</th>
                          <th scope="col">Unit Result</th>
                          <th scope="col">Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {result.map((rowData) => {
                          return <tr key={rowData.id}>
                            <td>{rowData.unit.name}</td>
                            <td>{rowData.page_number}</td>
                            <td>{rowData.unit.title}</td>
                            <td>{rowData.result} / {rowData.no_of_question}</td>
                            <td>{rowData.remark}</td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </Tab>
                  <Tab
                    title="Middle Test Result"
                    eventKey="Middle Test Result"
                    className="border-end border-bottom border-start p-5 rounded-bottom"
                  >
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Test</th>
                          <th scope="col">Count</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getResultDetails.map((rowData) => {
                          return <tr key={rowData.id}>
                            <td>{rowData.test}</td>
                            <td>{rowData.correct_answer_count}</td>
                            <td>
                              {/* {rowData.test} */}
                            </td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </Tab>
                  <Tab
                    title="Terminate"
                    eventKey="Terminate"
                    className="border-end border-bottom border-start p-5 rounded-bottom"
                  >
                    <div className="row g-4">
                      <div className="col-12 col-md-6 p-2 d-flex justify-content-center">
                        <button to="/units" className="belford-btn" onClick={getCancelSubscribtionModel}>
                          Terminate
                        </button>
                      </div>
                      <div className="col-12 col-md-6 p-2 d-flex justify-content-center">
                        <button to="/units" className="belford-btn">
                          Suspend
                        </button>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    title="Reset Password"
                    eventKey="resetpassword"
                    className="border-end border-bottom border-start p-5 rounded-bottom"
                  >
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="d-flex flex-column align-items-center gap-2">
                        <div className="col-12 col-md-6">
                          <div className="form">
                            <label htmlFor="new-password" className="form-label">New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="new-password"
                              placeholder="********************"
                              aria-label="New Password"
                              aria-invalid={errors.password ? "true" : "false"}
                              {...register("password", {
                                required: "New password is required",
                                minLength: {
                                  value: 8,
                                  message: "Password must be at least 8 characters long"
                                },
                                pattern: {
                                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
                                  message: "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
                                }
                              })}
                            />
                          </div>
                          {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form">
                            <label htmlFor="confirm-new-password" className="form-label">Confirm New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="confirm-new-password"
                              placeholder="********************"
                              aria-label="Confirm New Password"
                              aria-invalid={errors.password_confirmation ? "true" : "false"}
                              {...register("password_confirmation", {
                                required: "Please confirm your new password",
                                validate: value => value === getValues("password") || "Passwords do not match"
                              })}
                            />
                          </div>
                          {errors.password_confirmation && <div className="invalid-feedback">{errors.password_confirmation.message}</div>}
                        </div>
                        <div className="col-12">
                          <div className="form-group text-center mb-2">
                            <button type="submit" className="belford-btn">
                              Reset Password
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Tab>

                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={classShow}
        onHide={handleClassClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>TERMINATE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Cliccando su Continue, ti stai disiscrivendo da questo servizio. Per i clienti con una
            mensilità di pagamento attiva, non sarà addebitato più alcun importo a partire dal
            prossimo mese. Potrai accedere alla tua area personale fino alla fine della tua settimana
            gratuita o del mese corrente di iscrizione.</p>
          <p>By clicking on Continue, you are unsubscribing from this service. For customers with an
            active paid subscription, no fees will be charged starting from next month. You will be able
            to enter your personal area until the end of the free week trial or the end of the already
            paid month.</p>
          <p>En cliquant sur Continue, vous vous désabonnez de ce service. Pour les clients ayant un
            mois de paiement actif, vous ne serez plus facturé à partir du mois prochain. Vous pourrez
            accéder à votre espace personnel jusqu'à la fin de votre semaine gratuite ou du mois
            d'abonnement en cours.</p>
          <p>Si elige Continue, se dará de baja de este servicio. Para los clientes con un mes de pago
            activo, ya no se les cobrará a partir del próximo mes. Podrás acceder a tu área personal
            hasta el final de tu semana gratuita o mes actual de suscripción.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={getCancelSubscribtion}>Continue</Button>
        </Modal.Footer>
      </Modal>
    </Body>
  );
};

export default Profile;
