/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import Body from './common/Body'

const TermandCondition = () => {
    return (
        <Body>
            <section className="belford-calendar-hero-section" id="belford-calendar-hero-section">
                <div className="container">
                    <div className="row gy-4 justify-content-center align-items-center">
                        <div className="col">
                            <div className="belford-register-box text-center">
                                <h1> <span style={{ color: "#fff" }}>Terms & Conditions</span> </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-calendar-table-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                        <p>1.	General | Scope</p>

<p>1.1 These Belford Woods School End User Terms (“Terms”) apply to all contracts between Belford Woods School srls with registered office address at via Riccardo Grazioli Lante 15/A, 00195 Rome, Italy (“Belford Woods School”) and its customers (“you”) to whom Belford Woods School provides support in multiple languages (“Services”). </p>

<p>1.2 These Terms shall govern all documents related to orders of Belford Woods School Services, including any online, email or phone ordering processes that are agreed between you and Belford Woods School (each an “Order”). Each agreed Order shall incorporate these Terms.</p>

<p>1.3 These Terms shall also apply as a framework for future contracts between you and Belford Woods School even if Belford Woods School does not refer to them in each individual case. These Terms shall be deemed to be confirmed by you at the latest when you access the Services.</p>

<p>1.4 These Terms apply to the exclusion of all others. Different, conflicting or supplementary terms shall only become part of an Order if and to the extent that Belford Woods School has agreed to such terms in writing.</p>

<p>1.5 Individual agreements between you and Belford Woods School in specific cases shall take priority over these Terms if made in writing.</p>

<p>1.6 Where these Terms use the expressions 'in writing', 'written form' or variations thereof, this shall mean in writing within the meaning of the Italian Civil Code. This means the electronic exchange of copies of documents signed by hand as well as documents signed with a simple electronic signature shall suffice. Unless expressly stated otherwise in these Terms, simple emails shall not suffice.</p>

<p>1.7 Unless stated otherwise in these Terms, any notices, declarations, reservation of classes and/or communication made by you to Belford Woods School, must be carried out through the contact form provided in the website and in the reserved area for registered students (“Platform”). Communications and or messages, of any kind, sent by way of e-mails, text messages (WhatsApp included), etc., are not considered as a valid method of communication and Belford Woods School reserves the right to not take them into account. </p>

<p>2. Free Services and Paid Services | Updates | Trial Use</p>

<p>2.1 Subject to the terms and conditions agreed in each Order, Belford Woods School grants you access to its language learning Platform which is offered as software-as-a-service as well as learning content and features as specified in the Order. Belford Woods School may offer free of charge services with limited functionalities (“Free Services”) or services against payment of Service Fees with certain agreed functionalities (“Paid Services”), each as specified in the Order.</p>

<p>2.2 Belford Woods School may implement new versions and upgrades of Paid Services including, but not limited to, modifications to the design, operational method, technical specifications, systems, and other functions of Paid Services, at any time in accordance with applicable law, in particular to the extent that such changes are necessary to maintain the contractual compliance of Paid Services or for compelling technical, economic or legal reasons.</p>

<p>2.3 Belford Woods School may downgrade, limit or otherwise delete Free Services at any time without notice. </p>

<p>2.4 With regard to the Services (Blue and Golden Ribbons subscriptions) Belford Woods School may grant you access to Paid Services free of charge or at reduced Service Fees as part of a trial evaluation for a period determined by Belford Woods School in its sole discretion (“Trial Use”). Belford Woods School may downgrade, limit or otherwise delete the Services provided for Trial Use at any time without notice. Subject to the terms and conditions agreed in the Order, in the absence of a withdrawal, Trial Use of Services ends after 7 days from the subscription and automatically becomes regular Paid Services.</p>

<p>2.5 Belford Woods School may retain subcontractors, including third-party software suppliers, for the performance of any of its obligations under any Order at any time.</p>

<p>2.6 Services concerning translations, corporate courses, certificates, hourly lessons packages, Italian for foreigners course and legal English support does not provide for any kind of Trial Use or free of charges period/activity.  </p>

<p>3. User Account | Conclusion of an Order</p>

<p>3.1 In order to use the Platform, you must create a user account (“User Account”) by accepting these Terms, providing certain personal details, and by either setting a password of your choice in a registration form provided by Belford Woods School online. After registering, you will receive a confirmation email from Belford Woods School allowing you to activate the User Account. </p>

<p>3.2 You must be at least eighteen (18) years of age to create a User Account and/or to place an Order. If you are considered to be underaged by applicable law, you may only create a User Account and/or place an Order if you are at least fourteen (14) years of age and your legal representative has provided his/her prior written consent to be addressed at the following e-mail box: info@belfordwoodsschool.com. Belford Woods School may ask you to present proof of your identity, your legal age, and/or your legal representatives consent at any time. Belford Woods School shall not be liable for any breach by you of this article 3.2 and no inspection duty about age shall belong to Belford Woods School.</p>

<p>3.3 If you wish to access Paid Services, you may place an Order with Belford Woods School through your User Account. The ordering process usually includes the following steps: choosing an option, verifying the choice and/or correcting it, inserting relevant data, selecting the method of payment, reading the applicable commercial terms and accepting such terms, agreeing to these Terms, including privacy policy, and submitting a binding order by clicking an order button and/or checking the box to proceed. Your offer shall only turn into a binding Order when Belford Woods School confirms acceptance of your offer in an email (“Order Confirmation”). The Order Confirmation also includes detailed information in relation to your Order and an order number.</p>

<p>3.4 Belford Woods School will safely store your order. </p>

<p>4. Rights of Use | Use Restrictions</p>

<p>4.1 Subject to the terms and conditions of the Order, Belford Woods School grants to you, during the period of utilization (for Free and/or Paid Services) the use of the Service for your private purposes only (“License”). Belford Woods School offers you the Services as described on Belford Woods School's website including the user on-boarding process (if any) as well as the other website sections, in each case as updated from time to time (“Documentation”).</p>

<p>4.2 You must not (and must not permit any third party to) directly or indirectly: (a) sublicense, sell, resell, transfer, assign, distribute, share, lease, rent, make any external commercial use of, outsource, or otherwise generate income from the Services; (b) copy the Services into any public or distributed network; (c) decompile, reverse engineer or disassemble any portion of the Services, or otherwise attempt to discover any source code, object code or underlying structure, ideas, know-how or algorithms or other operational mechanisms of the Services, in each case, unless permitted by mandatory statutory law; (d) modify, adapt, translate, duplicate or create derivative works based on all or any part of the Services (except to the extent expressly permitted by written authorization by Belford Woods School or authorized within the Services); (e) modify any proprietary rights notices that appear in the Services or components thereof; (f) use any Services in violation of any applicable laws and regulations or outside of the scope of rights as set forth in this Clause 4; or (g) use the Services to (i) store, download or transmit infringing or otherwise unlawful or tortious material, or malicious code or malware; or (ii) engage in phishing, spamming, denial-of-service attacks or other fraudulent or criminal activity; (iii) interfere with or disrupt the integrity or performance of third-party systems, or the services or data contained therein; (iv) gain unauthorized access to the Services or Belford Woods School systems or networks; or (v) perform, or engage any third party to perform, penetration testing, vulnerability assessments or other security assessments.</p>

<p>4.3 You must not duplicate, process, distribute, share, or use for public reproduction any content of the Services without Belford Woods School's prior written consent, unless expressly permitted by sharing options that may be integrated in the Services.</p>

<p>4.4 You may only use the Services as prescribed in the Documentation.</p>

<p>4.5 Belford Woods School may monitor your use of the Services and may prohibit and/or suspend any use of the Services that Belford Woods School believes may be in violation of this Clause 4.</p>

<p>5. User's Responsibilities</p>

<p>5.1 You shall be responsible for obtaining and maintaining any technical equipment and related ancillary services required to connect to, access or otherwise use the Services, including, without limitation, hardware, software (collectively “System”), networks and internet connections at your own cost. </p>

<p>5.2 You must keep the information in your User Account up to date and correct. You must ensure that your user identities, passwords and similar credentials used for accessing the Services are used and stored in a secure manner, cannot be accessed and used by unauthorised third parties and are immediately changed in the event of unauthorised disclosure. You must notify Belford Woods School promptly of any unauthorised use of any password or account or any other known or suspected breach of security or misuse of the Services.</p>

<p>5.3 You shall be solely responsible for the content of all data exchanged or transmitted by you on or through the Services (“Content”).  Belford Woods School cannot control the information submitted by you during the use of the Services and cannot guarantee the accuracy of any information submitted.</p>

<p>5.4 Belford Woods School may, without notice or liability, investigate any complaints or suspected violations of the Order including these Terms that come to its attention and may take any action that it believes is appropriate, including, but not limited to, rejecting, refusing to post, or removing any Content, or other data, or restricting, suspending, or terminating your access to the Services.</p>

<p>5.5 You shall be liable for losses or damage incurred by Belford Woods School if you intentionally or negligently revealed your identity/password to a third party or, if your identity and password have otherwise become known to an unauthorised party.</p>

<p>6. Virtual Classroom</p>

<p>6.1 For Golden Ribbon subscribers only, Belford Woods School, starting from the Paid Service period, shall allow you to participate in language training sessions with language teachers in an online live stream, alternatively in one-to-one sessions (“Individual Class”) or in group sessions (“Group Class”) (collectively “Virtual Classroom”) subject to the provisions of this Clause 6. </p>

<p>6.2 Subject to Clause 3.2, you must be at least fourteen (14) years of age in order to participate in Group Classes.</p>

<p>6.3 For Golden Ribbon's subscribers, in order to book a Virtual Classroom session, you must log into your User Account and place a booking request by clicking a booking button. Each booking request is subject to availability of the requested time as well as the availability of a free seat in the desired session. Upon completion of the reservation procedure, the Virtual Classroom session shall become binding on you and Belford Woods School.</p>

<p>6.4 The booking of Virtual Classroom sessions is limited to the specific user. Virtual Classroom Services are limited to a maximum number of sessions per user per calendar month as described in the Documentation.</p>

<p>6.5 Virtual Classroom Services are usually provided via third-party video conferencing software. You shall be responsible for fulfilling the System Requirements with regard to Virtual Classroom Services.</p>

<p>6.6 Since third-party video conferencing is required to participate in a Virtual Classroom session you shall be solely responsible for obtaining such video conferencing software including any usage rights thereto at your own cost and Belford Woods School does not make any representations or warranties with regard to the availability and/or certain functionalities of such third-party video conferencing software.</p>

<p>6.7 Belford Woods School shall strive to make the desired language level available to you at the desired time under Virtual Classroom Services. However, Belford Woods School availabilities may be limited and changes may be required at short notice on important grounds. In particular, Belford Woods School may replace any designated language teacher with an equally qualified language teachers at any time before the start of a Virtual Classroom session without giving reasons. In case the Virtual Classroom Service(s) is postponed as per Belford Woods School request, You will be entitled to take the lesson again in an agreed future time.  </p>

<p>6.8 The content and scope as well as the time and duration of a Virtual Classroom session are set out on Belford Woods School's website. Belford Woods School may adapt and/or supplement the content and scope of a Virtual Classroom session at any time and in an appropriate manner, keeping in mind your interest. Clause 2.2 applies.</p>

<p>6.9 Belford Woods School and the language teachers shall be free to determine the specific contents and/or design of any Virtual Classroom session at their own discretion. With regard to Belford Woods School Individual Classes, Belford Woods School shall use commercially reasonable efforts to accommodate your requests on the specific contents and/or design of a session. Each Virtual Classroom session may include up to five (5) minutes of administrative activities (e.g. designation of homework, preparation of notes).</p>

<p>6.10 The Belford Woods School regulations concerning the Virtual Classroom sessions can be viewed in the Regulations page of the learning platform, and form an integral part of these Terms and any Order for Virtual Classroom Services. You must comply with the Regulations at all times. Belford Woods School shall not be responsible for the conduct of the users taking part in Virtual Classroom Services.</p>

<p>6.11 Belford Woods School and/or the language teachers may exclude you from a Virtual Classroom session at any time if you behave inappropriately, in particular if you violate applicable laws and/or the Regulations.</p>

<p>6.12 You may cancel any reservation to the Virtual Classroom session without giving reasons within and no later than 7PM (19:00 Italian time) of the previous day of the relevant scheduled Virtual Classroom session starting time. As per example, in case your Virtual Classroom session is scheduled for 6PM (18:00 Italian time) of April 2nd, 2024, you are entitled to cancel such session up to hour 6:59 PM (18:59 Italian time) of April 1st, 2024. </p>

<p>6.13 In order to be effective, any cancellation of a Virtual Classroom session by you must be made via your User Account.</p>

<p>6.14 If (a) you cancel a booked Virtual Classroom session after 19:00 of the day before its scheduled starting time, or (b) you do not attend a Virtual Classroom session in whole or in part, your class is considered as regularly attended.  </p>

<p>6.15 You may use any training material provided by Belford Woods School and/or a language teacher in connection with Virtual Classroom Services solely for the purpose of personal training preparation and follow-up as well as for personal information purposes. You may not duplicate, process, distribute or use for public reproduction any training material without the prior written consent of Belford Woods School. You must not (and must not permit any third party to) make any video and/or audio recordings of Virtual Classroom sessions.</p>

<p>6.16 Users attending Virtual Classrooms shall verify the proper functioning of their video camera which shall be turned-on at the beginning of every Virtual Classroom in order to let the teacher able to identify correspondence between attending users and the users who placed the Order. </p>

<p>6.17 For Blue Ribbon subscribers, once all level units are successfully concluded, the final test can be reserved through the calendar available on the platform. Blue Ribbon subscription does not allow users to book individual classes or group classes. </p>

<p>7. Maintenance and Support for Paid Services</p>

<p>7.1 Subject to your payment of the relevant fees, Belford Woods School shall provide Maintenance and Support for Paid Services to the extent specified in the Order. “Support” shall mean Belford Woods School's obligation to use commercially reasonable efforts to respond to your reasonable support requests with regard to Paid Services by troubleshooting issues and providing assistance within reasonable time. “Maintenance” shall mean Belford Woods School's obligations with regard to Paid Services related to error resolution, bug fixes and the provision of updates and upgrades made generally available by Belford Woods School in its sole discretion.</p>

<p>7.2 Belford Woods School provides Support to you via email through any email address that Belford Woods School may provide.</p>

<p>7.3 Belford Woods School shall use reasonable efforts in order to reduce as much as possible errors and interruptions in the Services. You may notify Belford Woods School of any errors via email at the provided addresses. </p>

<p>8. Availability of Paid Services</p>

<p>8.1 Subject to your payment of the relevant fees, Belford Woods School shall make Paid Services available to you in accordance with this Clause 8.</p>

<p>8.2 Belford Woods School shall use reasonable efforts to ensure, that Paid Services are available to you via the internet, excluding (a) any temporary unavailability for scheduled or for unscheduled Maintenance, (b) unavailability for causes beyond Belford Woods School's reasonable control, and (c) all Sundays and Italian and European recognized bank holidays. Belford Woods School shall use reasonable efforts to provide advance notice of any scheduled service disruption.</p>

<p>8.3 You are aware and acknowledge that Belford Woods School shall not be liable for any deficiencies in your internet connections or System.</p>

<p>9. Fees | Payment Terms | Limitation of Right to Set-off</p>

<p>9.1 You must pay the fees for Paid Services as specified in the Order in accordance with the terms therein (the “Service Fees”).</p>

<p>9.2 Belford Woods School may change the Service Fees for the Blue and Golden Ribbons upon at least sixty (60) days' prior notice with regard to the introduction of the new Service Fees to the current Users.</p>

<p>9.3 You must make payment of the Service Fees using any of the payment methods offered on Belford Woods School's website.</p>

<p>9.4 Belford Woods School, through its payment method, will send the relevant receipt once received the payment by You. </p>

<p>9.5 Belford Woods School may suspend your access to Paid Services, in accordance with applicable laws, in case You are in breach with the payment of the aforesaid Paid Services.</p>

<p>10. Belford Woods School as a Gift</p>

<p>10.1 To the extent offered by Belford Woods School, you may purchase vouchers for the Services under an Order (“Gift Vouchers”). Gift Vouchers may only be redeemed for the purchase of Paid Services but not for the purchase of other Gift Vouchers.</p>

<p>10.2 Unless otherwise agreed, a Gift Voucher consists of an electronic voucher code and Belford Woods School shall deliver the Gift Voucher to You by email to the email address provided by You.</p>

<p>10.3 Unless otherwise agreed, Gift Vouchers are transferable. Subject to these Terms, people receiving the Gift Vouchers may use such Gift Voucher to pay for Paid Services under a corresponding Order by entering the voucher code upon completion of the subscription. </p>

<p>10.4 Gift Vouchers do not allow You to convert the value of such vouchers into funds to require towards Belford Woods School. The value of the Gift Vouchers do not accrue any kind of interest and can be used by You in order to redeem Paid Services only. The validity of the Gift Vouchers is equal to 12 months form the date of the issuance. </p>

<p>11. Term | Termination</p>

<p>11.1 Any Order shall begin on the date specified therein.</p>

<p>11.2 Any Order for Free Services shall remain in effect for 7 calendar days, unless terminated in accordance with the Order including these Terms (“Free Term”). You or Belford Woods School may terminate any Order for Free Services for convenience at any time with immediate effect. Free Term is not renewable, and each user is entitled to have Free Service only once.  </p>

<p>11.3 Unless otherwise agreed, any Order for Paid Services shall remain in effect for 30 calendar days as specified in the Order (“Initial Service Term”). The Initial Service Term shall automatically and continuously renew for additional periods of 30 calendar days as specified in the Order (“Renewal Service Term”), unless terminated by Belford Woods School or by you at any time. Once the procedure of termination of the Service has been successfully completed by clicking the relevant button in the Platform, you will have access to personal area until the end of the 30 calendar days of subscription already paid. </p>
<p>11.4 Belford Woods School shall not be liable for any fees that you may incur in relation to third parties commissioned by you (e.g. banks and payment service providers) or for any non-refundable taxes as part of the withdrawal process.</p>

<p>12. Limited Warranty for Paid Services</p>

<p>12.1 You represent and warrant that you have checked that the specifications of the Services as described in the Documentation meet your needs and wishes. You are aware of the essential functionalities and features of the Services. The extent, nature and quality of the Services are determined by these Terms, the Order, and the Documentation. </p>

<p>12.2 You will not obtain the source code of the Services for any reason.</p>

<p>13. Proprietary Rights | User Data</p>

<p>13.1 Belford Woods School and its suppliers own and shall retain all proprietary rights, including all copyright, database rights, patents, trade secrets, trademarks and all other intellectual property rights and technical solutions, in and to the Services, including but not limited to any learning contents and didactic methods. You acknowledge that the rights granted under any Order do not provide you with title to or ownership in the Services.</p>

<p>13.2 You shall retain all rights, title and interest in and to your data and any content, as well as any data that is based on or derived from your data and any content. You grant to Belford Woods School a non-exclusive, non-transferable, sublicensable, worldwide and unlimited right and license to use your data and any content solely in connection with providing the Services. Belford Woods School shall have no liability for your data and any Content.</p>

<p>14. Data Privacy</p>

<p>14.1 The processing of personal data by Belford Woods School is described in Belford Woods School's privacy policy.</p>

<p>15. Indemnity</p>

<p>15.1 Without prejudice to any other liability of You under contract or statutory law, you shall defend, indemnify and hold harmless, at your expense, Belford Woods School and its affiliates (as defined herein below), its employees, subcontractors, suppliers and resellers against any third-party claim to the extent such claim arises from or is made in connection with a breach of Clause 4 or a violation of the Belford Woods School Regulations by You or otherwise from your use of Services, and you shall pay all costs and damages finally awarded against Belford Woods School by a court of competent jurisdiction as a result of any such claim. “Affiliate” shall mean any entity that directly or indirectly controls, is controlled by, or is under direct or indirect common control with Belford Woods School respectively, or which is a wholly owned subsidiary of Belford Woods School respectively, whereby 'control' shall mean owning, directly or indirectly, at least fifty-one percent (51% Belford Woods School corporate capital).</p>

<p>15.2 In connection with any claim for indemnity under this Clause 15, Belford Woods School must promptly provide you with notice of any claim that Belford Woods School believes is within the scope of the obligation to indemnify, provided, however, that the failure to provide such notice shall not relieve you of your obligations under this Clause 15, except to the extent that such failure materially prejudices your defence of such claim. Belford Woods School may, at its own expense and at its sole discretion assist in the defence and actively participate in the defence and in all negotiations related to the settlement of any such claim. Any such settlement intended to bind Belford Woods School shall not be final without Belford Woods School's written consent, which consent shall not be unreasonably withheld, conditioned or delayed.</p>

<p>15.3 Your rights under this Clause 15 shall be your sole remedy with respect to any claim of infringement of third-party intellectual property rights and trade secrets.</p>

<p>16. Limitation of Liability</p>

<p>16.1 Belford Woods School shall be liable for damage resulting from the breach of its primary obligations hereunder by Belford Woods School, its legal representatives, or personnel's. Primary obligations are such basic duties which form the essence of the Order and of the performance on which you may rely. If the breach of such primary obligation was caused by simple negligence by Belford Woods School, its legal representatives or personnel's, Belford Woods School's ensuing liability shall be limited to the amount which was foreseeable by Belford Woods School at the time the respective Service was performed.</p>

<p>16.2 Belford Woods School shall not be liable for damage resulting from the breach of non-primary obligations through simple negligence of Belford Woods School, its legal representatives or personnel.</p>

<p>16.3 Belford Woods School shall be liable for loss of data only up to the amount of typical recovery costs which would have arisen if proper and regular data backup measures had been taken by you.</p>

<p>16.4 Subject always to Clause 16.1, the total liability of Belford Woods School arising out of or in connection with any Order, whether in contract or tort or otherwise shall in no circumstances exceed a sum equal to one hundred percent (100%) of the total Service Fees paid or payable by you under the relevant Order.</p>

<p>16.5 Belford Woods School does not guarantee, represent or warrant any specific language learning progress or language learning success to be achieved by you by using the Services.</p>

<p>16.6 Neither Belford Woods School nor you shall be liable for any failure or delay in performance under of the Order including these Terms to the extent that such failure or delay is proximately caused by events outside the reasonable control of a party and occurring without that party's fault or negligence.</p>

<p>17. Assignment </p>

<p>17.1 Belford Woods School may assign any Order and/or any associated rights in whole or in part to a third party, provided that such assignment shall not impair your rights under such Order.</p>

<p>18. Entire Agreement | Severability | Changes</p>

<p>18.1 The Order including these Terms and the Regulations of the Platform represents the entire agreement between you and Belford Woods School in respect of its subject matter and supersedes all prior negotiations and agreements made between you and Belford Woods School in relation to its subject matter.</p>

<p>18.2 Should any provision of the Order including these Terms be or become ineffective or invalid in whole or in part, the effectiveness and validity of the other provisions shall not be affected. Such ineffective or invalid provision shall be replaced by a provision which comes as close as legally possible to what you and Belford Woods School would have agreed, pursuant to the meaning and purpose of the original provision and of the Order if they had recognized the ineffectiveness or invalidity of the original provision. If the ineffectiveness or invalidity of a provision is based on the determination of a certain level of performance or a certain timescale (deadline or fixed date), such ineffective or invalid level of performance or timescale shall be replaced by the level of performance or timescale which comes as close as legally possible to the original level of performance or timescale. The foregoing shall also apply to any possible omission in the Order including these Terms that was not intended by you and Belford Woods School.</p>

<p>18.3 Without prejudice to Clause 20, valid amendments or supplements to any Order must be mutually agreed and made in writing. The same shall apply to any agreement to deviate from or cancel this requirement of written form.</p>

<p>19. Revision of these Terms</p>

<p>19.1 Belford Woods School may amend and/or update these Terms for existing Orders from time to time with future effect and as necessary for technical, economic or legal reasons. Any revision of these Terms shall be announced to you in text form no later than four (4) weeks before their proposed effective date. You may either approve or object to the revision before their proposed effective date. The revision shall be deemed approved by you, unless you object to the revision before their proposed effective date. Belford Woods School shall expressly inform you thereof in the respective announcement.</p>

<p>19.2 Belford Woods School may amend and/or update these Terms from time to time for future Orders at any time for any reason without notice.</p>

<p>20. Governing Law</p>

<p>20.1 Any Order and any issues, disputes or claims (whether contractual or non-contractual) arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with Italian Laws. The United Nations Convention on Contracts for the International Sale of Goods (CISG) shall not apply.</p>


                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default TermandCondition
