import React, { useCallback, useEffect, useState } from "react";
import Body from "./common/Body";
import PrivateMenu from "./common/PrivateMenu";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API } from "../helper/api";
import { getCurrentUser } from "./../helper/Authorization";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k])

function formatDate(inputString) {
  const parsedDate = new Date(inputString);

  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Adding leading zero if needed
  const day = String(parsedDate.getDate()).padStart(2, "0"); // Adding leading zero if needed
  const hour = String(parsedDate.getHours()).padStart(2, "0"); // Adding leading zero if needed
  const minute = String(parsedDate.getMinutes()).padStart(2, "0"); // Adding leading zero if needed
  return `${year}-${month}-${day} ${hour}:${minute}`;
}

const api = new API();

const time = [
  { value: "09", text: "09-10" },
  { value: "10", text: "10-11" },
  { value: "11", text: "11-12" },
  { value: "12", text: "12-13" },
  { value: "13", text: "13-14" },
  { value: "14", text: "14-15" },
  { value: "15", text: "15-16" },
  { value: "16", text: "16-17" },
];
const currentHour = new Date().getHours();
const currentUser = getCurrentUser();

const Calender = () => {
  const [myEventsList, setMyEventsList] = useState([]);
  const [show, setShow] = useState(false);
  const [classShow, setClassShow] = useState(false);
  const [classTime, setClassTime] = useState(time);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [leaveData, setLeaveData] = useState([]);
  const [isPayment, setPayment] = useState(false);
  const [monthCount, setMonthCount] = useState(false);
  const [weekCount, setWeekCount] = useState(0);
  const currentDate = new Date();
  const currentHour = currentDate.getHours();

  // Get tomorrow's date in YYYY-MM-DD format
  const tomorrow = new Date();
  tomorrow.setDate(currentDate.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split('T')[0];
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    getCalendar();
    setTime();
    checkPaymentStatus(currentUser.created_at);
  }, []);

  const checkPaymentStatus = async (date) => {
    const givenDate = new Date(date);
    const sevenDaysLater = new Date(givenDate);
    sevenDaysLater.setHours(0, 0, 0, 0);
    sevenDaysLater.setDate(givenDate.getDate() + 7);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    setPayment(sevenDaysLater.getTime() <= currentDate.getTime());
  };

  const setTime = async () => {
    const futureTimes = time.filter(
      (hour) => parseInt(hour.value) >= parseInt(currentHour)
    );
    setClassTime(futureTimes);
  };


  const today = new Date();

  const minTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    8
  );

  const maxTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    21
  );

  const getCurrentTimeAndDisableNextDay = (date) => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour < 17) {
      return false
    } else {
      return false
    }
  };

  const getCalendar = async () => {
    try {
      const response = await api.get("CALENDER");
      if (response.status === true) {
        if (response?.data?.calendar.length > 0) {
          response?.data?.calendar.forEach((booked) => {
            const appointmentDate = new Date(booked.start).toISOString().split('T')[0];
            let levelId = '';
            let callType = '';
            if (booked["level_id"] === 1) {
              levelId = 'A1'
            } else if (booked["level_id"] === 2) {
              levelId = 'A2'
            } else if (booked["level_id"] === 3) {
              levelId = 'B1'
            } else if (booked["level_id"] === 4) {
              levelId = 'B2'
            } else if (booked["level_id"] === 5) {
              levelId = 'B1+'
            }

            if (booked['call_type'] === "group") {
              callType = 'Group';
            } else {
              callType = 'Personal';
            }
            console.log(appointmentDate, tomorrowDateString);
            if (appointmentDate === tomorrowDateString && currentHour >= 17) {
              booked.blockStatus = true;
            } else {
              booked.blockStatus = false;
            }
            booked["title"] = `${callType} Class ${levelId}`;
            booked["start"] = moment(booked["start"]).toDate();
            booked["end"] = moment(booked["end"]).toDate();
          });
        }
        if (response?.data?.leaves.length > 0) {
          response?.data?.leaves.forEach((booked) => {
            booked["title"] = "Leave";
            booked["start"] = moment(booked["start_date_time"]).startOf('day').toDate();
            booked["end"] = moment(booked["end_date_time"]).startOf('day').toDate();
          });
        }
        const eventDate = [
          ...response?.data?.calendar,
          ...response.data.leaves,
        ];
        console.log(eventDate);
        setMyEventsList(eventDate);
        setLeaveData(response.data.leaves);
        isDateDisabled();
        setMonthCount(countMonths(response?.data?.calendar));
        // countWeek(response?.data?.calendar);
      }
    } catch (error) {
      console.error("Error while get Calendar", error);
      toast.error(error?.data?.message);
    }
  };

  const countMonths = (data) => {
    const monthCounts = {};
    for (const item of data) {
      const startMonth = new Date(item.start).getMonth() + 1;
      monthCounts[startMonth] = (monthCounts[startMonth] || 0) + 1;
    }
    return monthCounts;
  };

  const countWeek = (calendar) => {
    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
    let calendarWithinCurrentWeek = 0;
    calendar.forEach(event => {
      const eventDate = new Date(event.start);
      if (eventDate >= firstday && eventDate <= lastday) {
        calendarWithinCurrentWeek++;
      }
    });

    setWeekCount(calendarWithinCurrentWeek);
  };

  const onDateHandler = (e) => {
    var today = new Date();
    var selectedDate = new Date(e);
    // eslint-disable-next-line eqeqeq
    if (today.toDateString() == selectedDate.toDateString()) {
      const futureTimes = time.filter(
        (hour) => parseInt(hour.value) >= parseInt(currentHour)
      );
      setClassTime(futureTimes);
    } else {
      setClassTime(time);
    }
    setStartDate(e);
  };

  const onTimeHandler = (e) => {
    var selectedTime = e.target.value;
    var futureTime = parseInt(selectedTime) + 1;
    setSelectedTime(`${selectedTime} to ${futureTime}`);
  };

  const onTypeHandler = (e) => {
    var selectedTime = e.target.value;
    // var futureTime = parseInt(selectedTime) + 1;
    // setSelectedTime(`${selectedTime} to ${futureTime}`);
  };

  const isDateDisabled = (date) => {
    if (!date) {
      return true;
    }
    if (new Date(date.getDay()) === 0) {
      return true;
    }
    for (const leave of leaveData) {
      const leaveStartDate = new Date(leave.leave_start_date_time);
      const leaveEndDate = new Date(leave.leave_end_date_time);
      leaveStartDate.setHours(0, 0, 0, 0);
      leaveEndDate.setHours(0, 0, 0, 0);
      if (date >= leaveStartDate && date <= leaveEndDate) {
        return false; // Disable date if it falls within a leave period
      }
    }
    return true; // Enable date if it does not fall within any leave period
  };

  const eventWrapperReserve = ({ event, children }) => {
    return (
      <div>
        {children}
        <button onClick={() => alert(`Clicked on ${event.title}`)}>Click Me</button>
      </div>
    );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClassClose = () => setClassShow(false);
  const cancelClassHandler = async() => {
    try{
      const response = await api.delete('APPOINTMENT_DELETE',  selectedClass['id']);
      if (response.status === true) {
        getCalendar();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch(error){
      console.error("Error while cancel class ", error);
      toast.error(error?.data?.message);
    }
  }
  // const handleClassShow = () => setClassShow(true);
  const handleClassShow = useCallback((event) => {
    event["start"] = formatDate(event["start"]);
    event["end"] = formatDate(event["end"]);
    setSelectedClass(event);
    setClassShow(true);
  }, []);

  const onSubmit = async () => {

    try {

      // const year = startDate.getFullYear();
      // const month = startDate.getMonth() + 1;
      // const day = startDate.getDate();
      // const checkNumberOfMonthBooking = monthCount[month.toString()];
      console.log(selectedClass);
      console.log(currentUser?.student?.level_id);
      console.log(selectedClass['course_id']);

      console.log(currentUser?.student);
      // if (selectedClass['course_id'] !== 1) {
      //   toast.error('Your Course should be Golden Ribbon');
      //   return;
      // } else

      if (selectedClass['level_id'] && selectedClass['level_id'] !== currentUser?.student?.level_id) {
        toast.error('Your level id is different.');
        return;
      }
      // else if(!isPayment && selectedClass['call_type'] !== 'group'){
      //   toast.error('Group booking is not allow.');
      //   return;
      // }

      // else
      // if(weekCount >= 1){
      //   toast.error('The booking limit for this week has been exceeded.');
      //   return;
      // } else
      // if(checkNumberOfMonthBooking >= 4){
      //   toast.error('The booking limit for this month has been exceeded.');
      //   return;
      // }

      // const request = {
      //   title: values.title,
      //   level_id: currentUser?.student?.level_id || 1,
      //   course_id: currentUser?.student?.course_id || 1,
      //   start_date_time: formatDate(
      //     `${year} ${month} ${day} ${values.time}:00:00`
      //   ),
      //   end_date_time: formatDate(
      //     `${year} ${month} ${day} ${Number(values.time) + 1}:00:00`
      //   ),
      //   student_id: currentUser?.id,
      //   description: "",
      //   call_type: values.call_type,
      // };

      const request = {
        "student_id": currentUser?.id
      }



      const response = await api.put(`BOOK_APPOINTMENT`, selectedClass['id'], request);
      if (response.status === true) {
        setClassTime(time);
        // reset();
        // setSelectedTime("");
        handleClose();
        toast.success("book appointment has been done successfully.");
        getCalendar();
      }
    } catch (error) {
      console.error("Error while book appointment ", error);
      toast.error(error?.data?.message);
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: "#FFC356",
    };

    if (event.title === "Leave") {
      style.backgroundColor = "red";
      style.color = "#fff";
    }
    if (event.level_id === 1) {
      style.backgroundColor = "#DEA9FF";
      style.color = "#000000";
    }
    if (event.level_id === 2) {
      style.backgroundColor = "#FFC356";
      style.color = "#000000";
    }
    if (event.level_id === 3) {
      style.backgroundColor = "#008AC9";
      style.color = "#000000";
    }
    if (event.level_id === 4) {
      style.backgroundColor = "#FF4B4B";
      style.color = "#000000";
    }
    if (event.level_id === 5) {
      style.backgroundColor = "#00A038";
      style.color = "#000000";
    }

    return {
      style,
    };
  };
  console.log(selectedClass, "selectedClass");

  return (
    <Body>
      <section
        className="belford-calendar-hero-section"
        id="belford-calendar-hero-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-14 col-lg-9">
              <div className="belford-calendar-hero-content text-center">
                <PrivateMenu />
                <h1>
                  <span className="belford-title-color">Calendar</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="belford-calendar-table-section"
        id="belford-calendar-table-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            {/* {(currentUser?.student?.course?.id === 1 || isPayment)&& (
              <div className="col-14">
                <button className="belford-add-event" onClick={handleShow}>
                  Book Class
                </button>
              </div>
            )} */}
            <div className="col-14">
              <div className="table-responsive">
                <Calendar
                  localizer={localizer}
                  events={myEventsList}
                  eventWrapper={eventWrapperReserve}
                  defaultView={Views.WEEK}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500 }}
                  // onSelectSlot={handleShow}
                  // onSelectEvent={handleClassShow}
                  onSelectEvent={handleClassShow}
                  eventPropGetter={eventStyleGetter}
                  views={allViews}
                  min={minTime}
                  max={maxTime}
                  selectable
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Book Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-4">
              <div className="col-12 col-md-12">
                <div className="form">
                  <label htmlFor="first_name" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Class Name"
                    aria-invalid={errors.title ? "true" : "false"}
                    {...register("title", {
                      required: "Title is required",
                    })}
                  />
                </div>
                {errors.title && (
                  <div className="invalid-feedback">{errors.title.message}</div>
                )}
              </div>
              <div className="col-12 col-md-12">
                <div className="form">
                  <label htmlFor="last_name" className="form-label">
                    Start date
                  </label>
                  <div>
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      minDate={new Date()}
                      filterDate={isDateDisabled}
                      onSelect={(e) => onDateHandler(e)}
                    />
                  </div>
                </div>
                {errors.start_date_time && (
                  <div className="invalid-feedback">
                    {errors.start_date_time.message}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-12">
                <div className="form">
                  <label htmlFor="last_name" className="form-label">
                    Type
                  </label>
                  <select
                    id="call_type"
                    className="form-select"
                    aria-invalid={errors.call_type ? "true" : "false"}
                    {...register("call_type", { required: "Type is required" })}
                    onChange={(e) => onTypeHandler(e)}
                  >
                    <option value="">Select Type</option>
                    <option value="group">Group</option>
                    <option value="individual">Individual</option>
                  </select>
                </div>
                {errors.call_type && (
                  <div className="invalid-feedback">
                    {errors.call_type.message}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-12">
                <div className="form">
                  <label htmlFor="last_name" className="form-label">
                    Time
                  </label>
                  <select
                    id="Package"
                    className="form-select"
                    aria-invalid={errors.time ? "true" : "false"}
                    {...register("time", { required: "Time is required" })}
                    onChange={(e) => onTimeHandler(e)}
                  >
                    <option value="">Select Time</option>
                    {classTime?.map((time, i) => (
                      <option value={parseInt(time.value)} key={i}>
                        {time.text}
                      </option>
                    ))}
                  </select>
                  {selectedTime && (
                    <small>Your class time will be {selectedTime}</small>
                  )}
                </div>
                {errors.time && (
                  <div className="invalid-feedback">{errors.time.message}</div>
                )}
              </div>
            </div>
            <div className="col-12 mt-5">
              <div className="form text-center">
                <button type="submit" className="belford-btn">
                  Add
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={classShow}
        onHide={handleClassClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Class Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedClass && (
            <>
              <p>Title : {selectedClass["title"]}</p>
              <p>Start date : {selectedClass["start"]}</p>
              <p>End date : {selectedClass["end"]}</p>
              <p>Type : {selectedClass["status"]}</p>
              {/* <p>Description : <span dangerouslySetInnerHTML={{__html:selectedClass["description"]}}></p> */}
            </>
          )}
          {/* <h4 className="text-center">Are you sure want to book this class ?</h4> */}
          <div className="col-12 mt-5">
            <div className="form text-center">

            {selectedClass.students.length > 0 ? (selectedClass.students.map((s) =>
                s.id === currentUser.id ? (
                  <button type="button" className="belford-btn" onClick={cancelClassHandler}>
                    Cancel
                  </button>
                ) : (
                  currentUser?.student?.course_id === 1 && !selectedClass['blockStatus'] && (
                    <button type="submit" className="belford-btn me-5" onClick={onSubmit}>
                      Reserve
                    </button>
                  )
                )
              )) : (
                currentUser?.student?.course_id === 1 && !selectedClass['blockStatus'] && (
                  <button type="submit" className="belford-btn me-5" onClick={onSubmit}>
                    Reserve
                  </button>
                )
              )}
              {/* <button type="button" className="belford-btn" onClick={handleClassClose}>
                Close
              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Body>
  );
};

export default Calender;
